import React from "react";
import Home from "../features/Home/Home";
import Layout from "../features/layout/Layout";

const HomePage = () => {
  return (
   
      <Layout>
        <Home />
      </Layout>
  
  );
};

export default HomePage;
