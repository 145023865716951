export const image = [
  {
    img: "/image/areas1.jpg",
    desc: "Sustainable Agro-industrial Value-network Innovation",
  },
  {
    img: "/image/small_Ethiopia_J_Ob_Creation_Through_Off_Grid_Energy_Access_04fa8ff9c9.jpg",
    desc: "Off-grid Energy Innovation",
  },
  {
    img: "/image/areas3.jpg",
    desc: "Development Policy Innovation",
  },
];
